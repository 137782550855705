import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Divider, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";

const EmailSendSuccess = () => {
  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const mid = url.searchParams.get("mid");

    if (mid === "REG_SUCCESS") {
      setMessage("Your account has been successfully registered.");
      setMessage2("an activation link has been sent to your email address.");
    } else if (mid === "FORGET_PASS") {
      setMessage("We have received a request to reset your password.");
      setMessage2("An email with a password reset link has been sent to your registered email address.");
    }
  }, [window.location.href]);

  return (
    <Grid
      container
      justifyContent="center"      
      sx={{
        backgroundColor: "#ffffff",
        my:5
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={10}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            px:4
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {message || "Mail send successfully."}
          </Typography>
          {message2 && (
            <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }}>
              {message2 || ""}
            </Typography>
          )}
          <Button
            variant="outlined"
            color="warning"
            sx={{ my: 3 }}
            onClick={(e) => {
              e.preventDefault();

              window.location.href = `${window.location.origin}`;
            }}
          >
            Go back
          </Button>
          <img src="./MailSent.gif" width="auto" height="auto" />
          <Typography variant="p" sx={{ my: 3, color: "red" }}>
            Note: If you are unable to find the email in your Inbox, kindly check your Spam or Junk folder as well.
            Sometimes, emails can be mistakenly filtered, and they may be located there.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmailSendSuccess;
