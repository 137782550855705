import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

function PaymentSuccess() {
  useEffect(() => {
    // Child (Payment) Window
    if (window.opener && window.opener?.location) {
      const parentOrigin = window.opener.location.origin;
      console.log("Parent window origin:", parentOrigin);
      setTimeout(() => {
        window.opener.postMessage("paymentSuccess", parentOrigin);
      }, 1000);
    } else {
      console.error("Payment window was not opened from another window.");
    }
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
          m: 1,
          p: 1,
          bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "#fff"),
          color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
          border: "1px solid",
          borderColor: (theme) => (theme.palette.mode === "dark" ? "grey.800" : "grey.300"),
          borderRadius: 2,
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          {" "}
          <Typography variant="h5">Payment Successful</Typography>
          <img src="/Images/animGreenTic.gif" alt="green_tic" />
          <Typography variant="body2">Thanks for choosing us.</Typography>
        </Box>
      </Box>
    </div>
  );
}

export default PaymentSuccess;
