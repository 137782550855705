import { Box, Button, Container, CssBaseline, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ForgotPassword = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [emailId, setEmailId] = useState("");

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!emailId) {
        toast.warn("All fields are required!");
        return;
      }

      if (!executeRecaptcha) {
        return;
      }
      const recaptchaToken = await executeRecaptcha("password_reset");

      apiService
        .get(`auth/forgotPassword/${emailId}?recaptchaToken=${recaptchaToken}`)
        .then((res) => res?.data || res)
        .then((response) => {
          toast.dismiss();
          if (response.resultCode > 0) {
            window.location.href = `${window.location.origin}/auth-success-notify?mid=FORGET_PASS`;
          } else {
            toast.error(response.message || "Fail");
          }
        })
        .catch((err) => {
          toast.dismiss();
          console.log(err);
          if (err?.response && err?.response?.data) {
            toast.error(err?.response?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <form onSubmit={handleSubmit}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
            </Box>
            <Typography component="h5" variant="h5" align="center">
              Forgot Password
            </Typography>

            <Divider sx={{ my: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="emailId"
                  type="email"
                  name="emailId"
                  label="Email ID"
                  fullWidth
                  helperText="abc@123"
                  variant="standard"
                  onChange={(e) => {
                    setEmailId(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
                Submit
              </Button>
            </Box>
          </Paper>
        </form>
      </Container>
    </>
  );
};

export default ForgotPassword;
