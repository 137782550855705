import React from 'react'
import { ToastContainer, cssTransition } from 'react-toastify'

const CustomToast = () => {
  return (
    <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover
        theme="colored"
      />
  )
}

export default CustomToast