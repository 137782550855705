import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Business, BusinessCenter, CorporateFare, Diversity2, Diversity3, EditNote, History, InfoOutlined, Security, Settings } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

export const MainListItems = ({myRights}) => {
  const navigate = useNavigate();
  const _color = "#1976d2";

  const teams_rights = myRights?.find((r) => r.access === "Teams");
  // const packages_rights = myRights?.find((r) => r.access === "Packages");
  const requests_rights = myRights?.find((r) => r.access === "Requests");
  const clients_organizations_rights = myRights?.find((r) => r.access === "Clients");
  const setting_rights = myRights?.find((r) => r.access === "Settings");
  const licenses_rights = myRights?.find((r) => r.access === "License");
  const products_rights = myRights?.find((r) => r.access === "Products");
  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => {
          navigate("/dashboard");
        }}
        sx={{ color: window.location.pathname.includes("dashboard") ? _color : "" }}
        selected={window.location.pathname.includes("dashboard")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("dashboard") ? _color : "" }}>
          <DashboardIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Dashboard"
        />
      </ListItemButton>

      <ListItemButton
        disabled={!teams_rights?.view}
        onClick={() => {
          navigate("/teams");
        }}
        sx={{ color: window.location.pathname.includes("teams") ? _color : "" }}
        selected={window.location.pathname.includes("teams")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("teams") ? _color : "" }}>
          <Diversity2 fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Teams Members"
        />
      </ListItemButton>

      <Divider sx={{ my: 1 }} />

      <ListItemButton
        disabled={!clients_organizations_rights?.view}
        onClick={() => {
          navigate("/clients");
        }}
        sx={{ color: window.location.pathname.includes("clients") ? _color : "" }}
        selected={window.location.pathname.includes("clients")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("clients") ? _color : "" }}>
          <Business fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Organizations"
        />
      </ListItemButton>

      {/* <ListItemButton
        onClick={() => {
          navigate("/mo-clients");
        }}
        sx={{ color: window.location.pathname.includes("mo-clients") ? _color : "" }}
        selected={window.location.pathname.includes("mo-clients")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("mo-clients") ? _color : "" }}>
          <CorporateFare fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Mobile & Other Clients"
        />
      </ListItemButton> */}

      <ListItemButton
        disabled={!products_rights?.view}
        onClick={() => {
          navigate("/products");
        }}
        sx={{ color: window.location.pathname.includes("products") ? _color : "" }}
        selected={window.location.pathname.includes("products")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("products") ? _color : "" }}>
          <LayersIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Products"
        />
      </ListItemButton>

      <ListItemButton
        disabled={!requests_rights?.view}
        onClick={() => {
          navigate("/requests");
        }}
        sx={{ color: window.location.pathname.includes("requests") ? _color : "" }}
        selected={window.location.pathname.includes("requests")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("requests") ? _color : "" }}>
          <EditNote fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Requests"
        />
      </ListItemButton>

      <ListItemButton
        disabled={!licenses_rights?.view}
        onClick={() => {
          navigate("/licenses");
        }}
        sx={{ color: window.location.pathname.includes("licenses") ? _color : "" }}
        selected={window.location.pathname.includes("licenses")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("licenses") ? _color : "" }}>
          <Security fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Licenses"
        />
      </ListItemButton>

      <ListItemButton
        disabled={!setting_rights?.view}
        onClick={() => {
          navigate("/settings");
        }}
        sx={{ color: window.location.pathname.includes("settings") ? _color : "" }}
        selected={window.location.pathname.includes("settings")}
      >
        <ListItemIcon sx={{ color: window.location.pathname.includes("settings") ? _color : "" }}>
          <Settings fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          primary="Settings"
        />
      </ListItemButton>

      {/* <ListItemButton>
      <ListItemIcon sx={{color:window.location.pathname.includes("dashboard")?_color:''}}>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                  }} primary="Reports" />
    </ListItemButton> */}
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Reports
    </ListSubheader> */}

    {/* <ListItemButton href="/history">
      <ListItemIcon sx={{color:window.location.pathname.includes("dashboard")?_color:''}}>
        <History />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                  }} primary="History" />
    </ListItemButton>

    <ListItemButton href="#">
      <ListItemIcon sx={{color:window.location.pathname.includes("dashboard")?_color:''}}>
        <InfoOutlined />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                  }} primary="About" />
    </ListItemButton> */}
  </React.Fragment>
);
